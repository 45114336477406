import { Injectable } from '@angular/core';
import { UserModel } from '@dsg/shared/data-access/user-api';

@Injectable({
  providedIn: 'root',
})
export class AuthDataService {
  private _formData = new UserModel();
  public setFormData(data: UserModel) {
    this._formData = data;
  }
  public getFormData() {
    return this._formData;
  }
  constructor() {}
}
