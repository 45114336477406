<mat-form-field [floatLabel]="label ? 'always' : 'auto'" [ngClass]="{'mdc-notched-outline--no-label': !label}" appearance="outline">
  <mat-label>{{label}}</mat-label>
  <textarea
    #formBaseInput
    [attr.aria-invalid]="formControl.invalid"
    [attr.aria-label]="ariaLabel"
    [attr.disabled]="disabled"
    [cdkAutosizeMaxRows]="autoSizeMaxRows"
    [cdkAutosizeMinRows]="autoSizeMinRows"
    [cdkTextareaAutosize]="autoSize"
    [formControl]="formControl"
    [maxlength]="maxlength"
    [placeholder]="placeholder"
    [required]="required"
    [rows]="autoSize ? null : rows"
    matInput
  ></textarea>
  <mat-hint *ngIf="hint" align="start">{{ hint }}</mat-hint>
  <mat-hint *ngIf="useMaxLength" align="end">{{ formControl.value?.length || 0 }}/{{ maxlength }}</mat-hint>
  <mat-error *ngIf="error$ | async as error"><nuverial-icon iconName="error_outline"></nuverial-icon>{{error}}</mat-error>
</mat-form-field>
