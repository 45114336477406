import { PagingResponseModel } from '@dsg/shared/utils/http';
import { DashboardCountModel, IDashboardCount } from '../dashboard/dashboard-count.model';
import { ITransactionStatusCount } from '../transaction-definition/transaction-definition.model';
import { ICustomerProvidedDocument, ITransaction, ITransactionActiveTask, ITransactionsPaginationResponse, TransactionModel } from './transaction.model';

export const TransactionStatusCountList: ITransactionStatusCount[] = [
  {
    count: 7,
    status: 'new',
  },
  {
    count: 22,
    status: 'draft',
  },
];

export const DashboardTabCountList: IDashboardCount[] = [
  {
    count: 7,
    tabLabel: 'New',
  },
  {
    count: 22,
    tabLabel: 'Draft',
  },
];

export const DashboardCountModelListMock: DashboardCountModel[] = DashboardTabCountList.map(count => new DashboardCountModel(count));

export const TransactionMock: ITransaction = {
  activeTasks: [],
  assignedTo: 'agent',
  createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
  createdByDisplayName: 'N/A',
  createdTimestamp: '2023-04-24T16:28:25.138799Z',
  data: {
    personalInformation: { firstName: 'Clyde' },
  },
  externalId: 'mw',
  id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
  isComplete: false,
  lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
  priority: 'high',
  processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
  status: 'draft',
  subjectProfileId: '123-456-789',
  subjectProfileType: 'INDIVIDUAL',
  subjectUserDisplayName: 'N/A',
  subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
  submittedOn: '2023-04-24T16:28:25.138799Z',
  transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
  transactionDefinitionKey: 'FinancialBenefit',
  transactionDefinitionName: 'Financial Benefit',
};

export const TransactionModelMock = new TransactionModel(TransactionMock);

export const TransactionToSchemaMock: Partial<ITransaction> = {
  assignedTo: 'agent',
  data: {
    personalInformation: { firstName: 'Clyde' },
  },
  priority: 'high',
};

export const TransactionMock2: ITransaction = {
  activeTasks: [],
  createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
  createdByDisplayName: 'N/A',
  createdTimestamp: '2023-04-24T16:28:25.138799Z',
  data: {
    personalInformation: { firstName: 'Clyde' },
  },
  externalId: 'ab',
  id: '7d8250c6-ee46-4040-aacc-7fc104360b43',
  isComplete: false,
  lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
  priority: 'medium',
  processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
  status: 'review',
  subjectProfileId: '123-456-789',
  subjectProfileType: 'INDIVIDUAL',
  subjectUserDisplayName: 'N/A',
  subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
  submittedOn: '2023-04-24T16:28:25.138799Z',
  transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
  transactionDefinitionKey: 'FinancialBenefit',
  transactionDefinitionName: 'Financial Benefit',
};

export const TransactionMock3: ITransaction = {
  activeTasks: [],
  createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
  createdByDisplayName: 'N/A',
  createdTimestamp: '2023-04-24T16:28:25.138799Z',
  customerProvidedDocuments: [
    {
      active: true,
      dataPath: 'documents.proofOfIncome',
      id: 'b30504e1-8ae2-4507-9b7f-7fd5fc72a917',
      isErrorTooltipOpen: false,
      rejectionReasons: ['POOR_QUALITY'],
      reviewStatus: 'REJECTED',
      reviewedBy: 'b5cec816-6aee-4648-b0a9-eba91b2b0168',
      reviewedOn: '2023-08-17T21:41:31.518951Z',
      transaction: 'a84f0223-be08-48c0-96cd-a34655eda6f2',
    },
  ],
  data: {
    personalInformation: { firstName: 'Julian' },
  },

  externalId: 'cd',
  id: 'a90fad12-41f6-11ee-be56-0242ac120002',
  isComplete: false,
  lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
  priority: 'medium',
  processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
  status: 'review',
  subjectProfileId: '123-456-789',
  subjectProfileType: 'INDIVIDUAL',
  subjectUserDisplayName: 'N/A',
  subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
  submittedOn: '2023-04-24T16:28:25.138799Z',
  transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
  transactionDefinitionKey: 'FinancialBenefit',
  transactionDefinitionName: 'Financial Benefit',
};

export const TransactionMockWithDocuments: ITransaction = {
  activeTasks: [],
  assignedTo: 'agent',
  createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
  createdByDisplayName: 'N/A',
  createdTimestamp: '2023-04-24T16:28:25.138799Z',
  customerProvidedDocuments: [
    {
      active: true,
      dataPath: 'documents.idBack',
      id: '6d0b34d5-7951-4775-87c5-a198ed3e9f01',
      isErrorTooltipOpen: false,
      rejectionReasons: [],
      reviewStatus: 'NEW',
      transaction: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    },
    {
      active: true,
      dataPath: 'documents.idFront',
      id: '2d0b34d5-7951-4775-87c5-a198ed3e9f01',
      isErrorTooltipOpen: false,
      rejectionReasons: ['Incorrect Type', 'Poor Document Quality'],
      reviewStatus: 'REJECTED',
      reviewedBy: 'John Tester',
      reviewedOn: '2023-07-28T14:51:34.836Z',
      transaction: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    },
    {
      active: true,
      dataPath: 'documents.proofOfIncome',
      id: '4c69ae11-8541-4cfa-a073-c3644ba78f9e',
      isErrorTooltipOpen: false,
      rejectionReasons: ['Name on form does not match name on application'],
      reviewStatus: 'REJECTED',
      reviewedBy: 'John Tester',
      reviewedOn: '2023-07-28T14:51:34.836Z',
      transaction: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    },
  ],
  data: {
    personalInformation: { firstName: 'Clyde' },
  },
  externalId: 'mw',
  id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
  isComplete: false,
  lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
  priority: 'high',
  processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
  status: 'draft',
  subjectProfileId: '123-456-789',
  subjectProfileType: 'INDIVIDUAL',
  subjectUserDisplayName: 'N/A',
  subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
  submittedOn: '2023-04-24T16:28:25.138799Z',
  transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
  transactionDefinitionKey: 'FinancialBenefit',
  transactionDefinitionName: 'Financial Benefit',
};

export const CustomerProvidedDocumentMock: ICustomerProvidedDocument = {
  active: true,
  dataPath: 'documents.idBack',
  id: '6d0b34d5-7951-4775-87c5-a198ed3e9f01',
  isErrorTooltipOpen: false,
  parentKey: 'photoId',
  rejectionReasons: ['Incorrect Type'],
  reviewStatus: 'ACCEPTED',
  reviewedBy: 'John Tester',
  reviewedOn: '2023-07-28T14:51:34.836Z',
  transaction: '7d8250c6-ee46-4040-aacc-7fc104360b42',
};
export const CustomerProvidedDocumentMock2: ICustomerProvidedDocument = {
  active: true,
  dataPath: 'documents.proofOfIncome',
  id: '824f1abd-b698-4967-b0ab-a050f840b94a',
  isErrorTooltipOpen: false,
  parentKey: 'proofOfIncome',
  rejectionReasons: [],
  reviewStatus: 'ACCEPTED',
  reviewedBy: 'John Tester',
  reviewedOn: '2023-07-28T14:51:34.836Z',
  transaction: '7d8250c6-ee46-4040-aacc-7fc104360b42',
};
export const CustomerProvidedDocumentMock3: ICustomerProvidedDocument = {
  active: true,
  dataPath: 'documents.idFront',
  id: '27999b66-49ff-4756-b2b6-bc7c79529676',
  isErrorTooltipOpen: false,
  parentKey: 'photoId',
  rejectionReasons: [],
  reviewStatus: 'ACCEPTED',
  reviewedBy: 'John Tester',
  reviewedOn: '2023-07-28T14:51:34.836Z',
  transaction: '7d8250c6-ee46-4040-aacc-7fc104360b42',
};

export const TransactionListMock: ITransaction[] = [
  {
    activeTasks: [],
    assignedTo: '3f7efb30-1a32-4a61-808a-64a60dbbee27',
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    customerProvidedDocuments: [
      {
        active: true,
        dataPath: 'documents.photoId',
        id: '6d0b34d5-7951-4775-87c5-a198ed3e9f01',
        isErrorTooltipOpen: false,
        rejectionReasons: ['Incorrect Type'],
        reviewStatus: 'ACCEPTED',
        transaction: '3d8250c6-ee46-4040-aacc-7fc104360b42',
      },
    ],
    data: {
      personalInformation: { firstName: 'Janie' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '3d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'high',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Approved',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    assignedTo: '222',
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Clyde' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '4d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'In Progress',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    assignedTo: '8888',
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Clyde' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '5d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Info Received',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    assignedTo: '9999',
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Clyde' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '6d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Submitted',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    assignedTo: '3f7efb30-1a32-4a61-808a-64a60dbbee29',
    createdBy: '3f7efb30-1a32-4a61-808a-64a60dbbee29',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Clyde' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '6d8250c6-ee46-4040-aacc-7fc104360b43',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Submitted',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '3f7efb30-1a32-4a61-808a-64a60dbbee29',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Clyde' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Approved',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Clyde' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'In Progress',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Clyde' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Info Received',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Jennifer' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Submitted',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Monica', lastName: 'Geller' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'high',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Approved',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Rachel', lastName: 'Green' },
    },
    externalId: 'ttyhhkfgh',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'In Progress',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Clyde' },
    },
    externalId: 'ttyhhkfgh',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Info Received',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Clyde' },
    },
    externalId: 'ttyhhkfgh',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Submitted',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Johanna', lastName: 'Johnson' },
    },
    externalId: 'ttyhhkfgh',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'high',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Approved',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Johanna', lastName: 'Johnson' },
    },
    externalId: 'ttyhhkfgh',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'In Progress',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'James', lastName: 'Joe' },
    },
    externalId: 'hgtjghjfghf',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Info Received',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Johanna', lastName: 'Johnson' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'low',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Submitted',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Clyde' },
    },
    externalId: 'ghjjgghjghj',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'high',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'In Progress',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Clyde' },
    },
    externalId: 'afdsdfsdf',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'medium',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Info Requested',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Clyde' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'high',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Info Requested',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Jim', lastName: 'Smith' },
    },
    externalId: 'askdjalksdjaksdj',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'medium',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Info Requested',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
  {
    activeTasks: [],
    createdBy: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    createdByDisplayName: 'N/A',
    createdTimestamp: '2023-04-24T16:28:25.138799Z',
    data: {
      personalInformation: { firstName: 'Rona' },
    },
    externalId: 'dfsdfsfsdfsdf',
    id: '7d8250c6-ee46-4040-aacc-7fc104360b42',
    isComplete: false,
    lastUpdatedTimestamp: '2023-04-24T16:28:25.138799Z',
    priority: 'medium',
    processInstanceId: '094ada65-e2bd-11ed-a0bc-9ef7d53fcb59',
    status: 'Approved',
    subjectProfileId: '123-456-789',
    subjectProfileType: 'INDIVIDUAL',
    subjectUserDisplayName: 'N/A',
    subjectUserId: '7cec2218-c55f-4b5f-a1d7-ab77989081c7',
    submittedOn: '',
    transactionDefinitionId: '74afbd29-4590-4e3d-ba96-92c886045dd5',
    transactionDefinitionKey: 'financialBenefit',
    transactionDefinitionName: '',
  },
];

export const TransactionListSchemaMock: ITransactionsPaginationResponse<ITransaction> = {
  items: TransactionListMock,
  pagingMetadata: new PagingResponseModel({
    nextPage: '',
    pageNumber: 1,
    pageSize: 10,
    totalCount: 200,
  }),
};

export const SingleTransactionModelListSchemaMock: ITransactionsPaginationResponse<TransactionModel> = {
  items: [TransactionModelMock],
  pagingMetadata: new PagingResponseModel({
    nextPage: '',
    pageNumber: 1,
    pageSize: 10,
    totalCount: 200,
  }),
};

export const TransactionActiveTasksMock: ITransactionActiveTask[] = [
  {
    actions: [
      {
        key: 'Approve',
        uiClass: 'Primary',
        uiLabel: 'Approve',
      },
      {
        key: 'Deny',
        uiClass: 'Secondary',
        uiLabel: 'Deny',
      },
      {
        key: 'RequestChanges',
        uiClass: 'Adverse',
        uiLabel: 'Request Changes',
      },
    ],
    key: 'review',
    name: 'Review',
  },
];

export const TransactionActiveTasksMock2: ITransactionActiveTask[] = [
  {
    actions: [
      {
        key: 'Approve',
        uiClass: 'Primary',
        uiLabel: 'Approve',
      },
      {
        key: 'Deny',
        uiClass: 'Secondary',
        uiLabel: 'Deny',
      },
      {
        key: 'RequestChanges',
        uiClass: 'Secondary',
        uiLabel: 'Request Changes',
      },
      {
        key: 'Submit',
        uiClass: 'Primary',
        uiLabel: 'Re-submit',
      },
    ],
    key: 'review',
    name: 'Review',
  },
];
