<section>
  <nuverial-card>
    <div class="title" nuverialCardContentType="title">
      <nuverial-icon iconName="block" />
      <h1>Unauthorized access</h1>
    </div>

    <div nuverialCardContentType="content">
      <p>If you believe this is an error, please contact your company administrator for help.</p>
    </div>
  </nuverial-card>
</section>
