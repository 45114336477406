import { PagingResponseModel } from '@dsg/shared/utils/http';
import { ITransactionsPaginationResponse } from '../transaction/transaction.model';
import { ISchemaDefinition } from './schema-definition.model';

export const SchemaDefinitionMock: ISchemaDefinition = {
  attributes: [
    {
      constraints: [],
      name: 'firstName',
      type: 'String',
    },
    {
      constraints: [],
      name: 'middleName',
      type: 'String',
    },
    {
      constraints: [],
      name: 'lastName',
      type: 'String',
    },
    {
      constraints: [],
      name: 'dateOfBirth',
      type: 'LocalDate',
    },
    {
      constraints: [],
      name: 'email',
      type: 'String',
    },
    {
      constraints: [],
      name: 'phone',
      type: 'String',
    },
  ],
  createdBy: 'user1',
  createdTimestamp: '2023-09-19T15:52:16.584283Z',
  description: 'description',
  id: 'id',
  key: 'FinancialBenefit',
  lastUpdatedBy: '',
  lastUpdatedTimestamp: '2023-09-19T15:52:16.584283Z',
  name: 'CommonPersonalInformation',
};

export const SchemaDefinitionListMock: ISchemaDefinition[] = [
  {
    attributes: [],
    createdBy: 'a658ad3b-2aee-4144-be15-8442cb0094ed',
    createdTimestamp: '2023-09-28T16:15:23.937102Z',
    description: 'A testing descriptione',
    id: 'a89ac734-e8ab-4951-8534-8ab71156de98',
    key: 'commonAddress',
    lastUpdatedBy: '',
    lastUpdatedTimestamp: '2023-09-28T16:15:23.937102Z',
    name: 'Common address',
  },
  {
    attributes: [],
    createdBy: '3f7efb30-1a32-4a61-808a-64a60dbbee27',
    createdTimestamp: '2023-09-28T16:15:23.937102Z',
    description: 'A testing descriptione',
    id: 'c58115d0-7789-454c-865c-2405caf44b5b',
    key: 'commonEmploymentInformation',
    lastUpdatedBy: '',
    lastUpdatedTimestamp: '2023-09-28T16:15:23.937102Z',
    name: 'Common employment information',
  },
  {
    attributes: [],
    createdBy: 'a658ad3b-2aee-4144-be15-8442cb0094ed',
    createdTimestamp: '2023-09-28T16:15:23.937102Z',
    description: 'A testing descriptione',
    id: '30cf63d1-7b28-4f4f-be0d-6073b6387c90',
    key: 'commonPersonalInformation',
    lastUpdatedBy: '',
    lastUpdatedTimestamp: '2023-09-28T16:15:23.937102Z',
    name: 'Common personal information',
  },
  {
    attributes: [],
    createdBy: 'a658ad3b-2aee-4144-be15-8442cb0094ed',
    createdTimestamp: '2023-09-28T16:15:23.937102Z',
    description: 'A testing descriptione',
    id: '35d0cd84-035e-4eee-92e7-de41f5cad33d',
    key: 'demoAdditionalFields',
    lastUpdatedBy: '',
    lastUpdatedTimestamp: '2023-09-28T16:15:23.937102Z',
    name: 'Demo Additional Fields',
  },
  {
    attributes: [],
    createdBy: 'a658ad3b-2aee-4144-be15-8442cb0094ed',
    createdTimestamp: '2023-09-28T16:15:23.937102Z',
    description: 'A testing descriptione',
    id: '1f1494a8-09a4-4d6f-95a0-9fd5bdebf912',
    key: 'financialBenefit',
    lastUpdatedBy: '',
    lastUpdatedTimestamp: '2023-09-28T16:39:00.045305Z',
    name: 'Financial benefit',
  },
  {
    attributes: [],
    createdBy: 'a658ad3b-2aee-4144-be15-8442cb0094ed',
    createdTimestamp: '2023-09-28T16:15:23.937102Z',
    description: 'A testing descriptione',
    id: 'a99b0080-4d81-4944-9712-074fbe5ccf72',
    key: 'financialBenefitDocuments',
    lastUpdatedBy: '',
    lastUpdatedTimestamp: '2023-09-28T16:15:23.937102Z',
    name: 'Financial Benefit Documents',
  },
  {
    attributes: [],
    createdBy: 'a658ad3b-2aee-4144-be15-8442cb0094ed',
    createdTimestamp: '2023-09-28T16:15:23.937102Z',
    description: 'A testing descriptione',
    id: '82877f98-1e08-497e-93b8-b83b7c703832',
    key: 'financialBenefitEligibility',
    lastUpdatedBy: '',
    lastUpdatedTimestamp: '2023-09-28T16:15:23.937102Z',
    name: 'Financial Benefit Eligibility',
  },
  {
    attributes: [],
    createdBy: 'a658ad3b-2aee-4144-be15-8442cb0094ed',
    createdTimestamp: '2023-09-28T16:15:23.937102Z',
    description: 'A testing descriptione',
    id: 'bbbec520-1641-4b01-9714-68d8d10eb84b',
    key: 'incomeTaxInformation',
    lastUpdatedBy: '',
    lastUpdatedTimestamp: '2023-09-28T16:15:23.937102Z',
    name: 'Income Tax Information',
  },
  {
    attributes: [],
    createdBy: 'a658ad3b-2aee-4144-be15-8442cb0094ed',
    createdTimestamp: '2023-09-28T16:15:23.937102Z',
    description: 'A testing descriptione',
    id: '24e827e2-4a25-434f-bc27-7027eb97e67e',
    key: 'testSchema',
    lastUpdatedBy: '',
    lastUpdatedTimestamp: '2023-09-28T16:15:23.937102Z',
    name: 'testSchema',
  },
  {
    attributes: [],
    createdBy: 'a658ad3b-2aee-4144-be15-8442cb0094ed',
    createdTimestamp: '2023-09-28T16:15:23.937102Z',
    description: 'A testing descriptione',
    id: '4ff0198f-6900-4cfd-8039-211ec970bb6f',
    key: 'testSchemaDeibys',
    lastUpdatedBy: '',
    lastUpdatedTimestamp: '2023-09-28T16:15:23.937102Z',
    name: 'testSchemaDeibys',
  },
];

export const SchemaDefinitionListSchemaMock: ITransactionsPaginationResponse<ISchemaDefinition> = {
  items: SchemaDefinitionListMock,
  pagingMetadata: new PagingResponseModel({
    nextPage: '',
    pageNumber: 1,
    pageSize: 10,
    totalCount: 200,
  }),
};
