<div class="nuverial-checkbox-card-container" [ngClass]="{'image-position-before': imagePosition === 'before'}">
  <label class="nuverial-checkbox-card-label-container" [for]="inputId" [ngClass]="{ 'checkbox-card-checked': checked, 'checkbox-card-disabled': disabled }">
    <div class="nuverial-checkbox-card-image-container">
      <ng-content select="[nuverialCardContentType='image']"></ng-content>
    </div>
    <div class="nuverial-checkbox-card-label-content">
      <mat-checkbox
        class="nuverial-checkbox-card"
        #formBaseInput
        [aria-describedby]="ariaDescribedBy || ''"
        [aria-label]="ariaLabel || ''"
        [checked]="checked"
        [color]="$any(colorTheme)"
        [disabled]="disabled"
        [indeterminate]="indeterminate"
        [ngClass]="{ 'nuverial-checkbox-card-invalid': invalid$ | async, 'nuverial-checkbox-card-forced-invalid': invalid}"
        [required]="required"
        [value]="value"
        (change)="onChange($event)"
      >
      </mat-checkbox>
      <div class="nuverial-checkbox-card-content-container">
        <ng-content select="[nuverialCardContentType='title'], [nuverialCardContentType='content']"></ng-content>
      </div>
    </div>
  </label>
</div>
<ng-container *ngIf="displayError">
  <nuverial-form-field-error class="nuverial-checkbox-error" *ngIf="(invalid$ | async) && (error$ | async) as error">
    <nuverial-icon iconName="error_outline"></nuverial-icon>
    {{ error }}</nuverial-form-field-error
  >
</ng-container>
