<mat-form-field [floatLabel]="label ? 'always' : 'auto'" [ngClass]="{'mdc-notched-outline--no-label': !label}" appearance="outline">
  <mat-label *ngIf="label !== undefined">{{label}}</mat-label>
  <nuverial-button *ngIf="prefixIcon" [ariaLabel]="prefixAriaLabel" (click)="clickIcon.emit('prefix')" buttonStyle="icon-no-border" matPrefix>
    <nuverial-icon [iconName]="prefixIcon"></nuverial-icon>
  </nuverial-button>
  <input
    #formBaseInput
    [attr.aria-invalid]="formControl.invalid"
    [attr.aria-label]="ariaLabel || label"
    [attr.autocomplete]="autocomplete ? autocomplete : null"
    [attr.disabled]="disabled"
    [formControl]="formControl"
    [mask]="maskPattern"
    [maxlength]="maxlength"
    [placeholder]="placeholder"
    [required]="required"
    [type]="inputType"
    matInput
  />
  <nuverial-button
    *ngIf="inputSuffixIcon"
    [ariaDescribedBy]="ariaDescribedBy || ''"
    [ariaLabel]="suffixAriaLabel"
    (click)="handleClickSuffixIcon()"
    buttonStyle="icon-no-border"
    matSuffix
  >
    <nuverial-icon [iconName]="inputSuffixIcon"></nuverial-icon>
  </nuverial-button>
  <div class="nuverial-text-input-tooltip" *ngIf="tooltip" [matTooltip]="tooltip" tabindex="0">
    <span class="cdk-visually-hidden">{{ tooltip }}</span>
    <nuverial-icon iconName="info_outline"></nuverial-icon>
  </div>
  <mat-hint *ngIf="hint" align="start">{{ hint }}</mat-hint>
  <mat-hint *ngIf="useMaxLength" align="end">{{ formControl.value?.length || 0 }}/{{ maxlength }}</mat-hint>
  <mat-error *ngIf="error$ | async as error"><nuverial-icon iconName="error_outline"></nuverial-icon>{{error}}</mat-error>
</mat-form-field>
