export interface CommunicationLogModel {
  riderId?: string;
  conversations: Conversation[];
}

export interface Conversation {
  conversationName?: string;
  createdAt?: Date;
}

export interface Message {
  agentMsg?: string;
  userMsg?: string;
  turnPosition?: number;
  timestamp?: Date;
}

export interface ConversationMessages {
  conversationName: string;
  messages: Message[];
}
