import {
  AuthenticationProviderActions,
  AuthenticationProviderErrorType,
  ClientAuthenticationProviderErrorLanguageType,
  ClientAuthenticationLanguageType,
} from './authentication.model';

export const DEFAULT_LANGUAGE: ClientAuthenticationLanguageType = {
  [AuthenticationProviderActions.SignInWithEmailAndPassword]: {
    emailAddressFormLabel: 'Email',
    emailAddressFormPlaceHolder: 'Enter email address',
    emailAddressValidationInvalid: 'Invalid email address',
    emailAddressValidationRequired: 'Email address is required',
    footerLinkText: 'Sign Up',
    footerText: 'Don’t have an account?',
    formSubmitLinkText: 'Forgot password',
    formSubmitText: 'Sign in',
    headerSubText: 'Enter your email and password to sign in.',
    headerText: 'Welcome back!',
    passwordFormDisplayDisabled: 'Enable password display',
    passwordFormDisplayEnabled: 'Disable password display',
    passwordFormLabel: 'Password',
    passwordFormPlaceHolder: 'Enter password',
    passwordValidationMin: 'Password must be at least 6 characters long',
    passwordValidationRequired: 'Password is required',
    statusErrorHeaderText: 'Failed',
    statusErrorLinkText: 'Return to sign in',
    statusErrorSubText: 'Unable to access your account',
  },
  [AuthenticationProviderActions.SignUpWithEmailAndPassword]: {
    emailAddressFormLabel: 'Email',
    emailAddressFormPlaceHolder: 'Enter email address',
    emailAddressValidationInvalid: 'Invalid email address',
    emailAddressValidationRequired: 'Email address is required',
    footerLinkText: 'Sign In',
    footerText: 'Already have an account?',
    formSubmitText: 'Sign up',
    headerSubText: 'Please fill in required fields below.',
    headerText: 'Sign Up to DSGov',
    passwordFormDisplayDisabled: 'Enable password display',
    passwordFormDisplayEnabled: 'Disable password display',
    passwordFormLabel: 'Password',
    passwordFormPlaceHolder: 'Enter password',
    passwordValidationMin: 'Password must be at least 6 characters long',
    passwordValidationRequired: 'Password is required',
    statusErrorDefaultMessage: 'Please return to the Sign Up page and try again.',
    statusErrorExistsMessage: 'An account with this email already exists',
    statusErrorHeaderText: 'Failed',
    statusErrorLinkText: 'Return to sign up',
    statusErrorSubText: 'Unable to create your account',
    statusSuccessHeaderText: 'Success',
    statusSuccessMessage: 'Please click the link sent to your email account to verify your email address.',
    statusSuccessSubText: 'Your account has been created!',
  },
  [AuthenticationProviderActions.SignUpWithEmailLink]: {
    emailAddressFormLabel: 'Email',
    emailAddressFormPlaceHolder: 'Enter email address',
    emailAddressValidationInvalid: 'Invalid email address',
    emailAddressValidationRequired: 'Email address is required',
    footerLinkText: 'Sign In',
    footerText: 'Already have an account?',
    formSubmitText: 'Sign up',
    headerSubText: 'Please fill in required fields below.',
    headerText: 'Sign Up to DSGov',
    statusErrorDefaultMessage: 'Please return to the Sign Up page and try again.',
    statusErrorExistsMessage: 'An account with this email already exists',
    statusErrorHeaderText: 'Failed',
    statusErrorLinkText: 'Return to sign up',
    statusErrorSubText: 'Unable to create your account',
    statusSuccessHeaderText: 'Success',
    statusSuccessMessage: 'Please click the link sent to your email account to verify your email address.',
    statusSuccessSubText: 'Your account has been created!',
  },
  [AuthenticationProviderActions.SignInWithEmailLink]: {
    statusErrorDefaultMessage: 'Please return to the Sign Up page and try again.',
    statusErrorExistsMessage: 'An account with this email already exists',
    statusErrorHeaderText: 'Failed',
    statusErrorLinkText: 'Return to sign up',
    statusErrorSubText: 'Unable to create your account',
    statusSuccessHeaderText: 'Success',
    statusSuccessMessage: 'Please click the link sent to your email account to verify your email address.',
    statusSuccessSubText: 'Your account has been created!',
  },
  [AuthenticationProviderActions.SignOut]: {
    footerLinkText: '< Back to sign in',
    headerSubText: 'You have successfully signed out',
    headerText: 'Sign Out',
    statusSuccessMessage: 'To sign back in, please go back to the Sign In page.',
  },
  [AuthenticationProviderActions.SessionExpired]: {
    sessionInactivityMessage: 'You have been logged out due to inactivity.',
  },
};

export const FIREBASE_ERROR_MAP: Record<string, AuthenticationProviderErrorType> = {
  'auth/email-already-in-use': 'email-already-in-use',
  'auth/invalid-email': 'invalid-email',
  'auth/user-disabled': 'user-disabled',
  'auth/user-not-found': 'user-not-found',
  'auth/weak-password': 'weak-password',
  'auth/wrong-password': 'wrong-password',
};

export const DEFAULT_ERROR_LANGUAGE: ClientAuthenticationProviderErrorLanguageType = {
  'email-already-in-use': 'An account with this email address already exists. If you forgot your password - please follow password reset instructions',
  'generic': 'An error occurred. Please try again later',
  'invalid-email': 'This is not a valid email address. Please verify and try again',
  'user-disabled': 'An account with this email address is disabled. Please contact Administrator',
  'user-not-found': 'Invalid email address or password',
  'weak-password': 'Weak password. Enter a password more than 6 characters and contains a number',
  'wrong-password': 'Invalid email address or password',
};
